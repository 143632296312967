import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
const {
  palette: defaultPalette
} = createTheme();
const CDSPalette = {
  // Overrides
  primary: {
    main: "#1F394C",
    light: "#2F5774",
    dark: "#0C161D",
    contrastText: "#FFFFFF"
  },
  secondary: {
    main: "#0A7FAE",
    light: "#0C9BD4",
    dark: "#09719A",
    contrastText: "#FFFFFF"
  },
  // Custom
  link: {
    light: "#0A7FAE",
    dark: "#95D7EF"
  },
  tertiary: {
    main: "#EECCAA"
  },
  quaternary: {
    main: "#616C74"
  },
  badge: {
    main: "#d2e3ed"
  },
  epiOrange: {
    main: "#f7542b"
  },
  notes: {
    // Note 1
    questionnaire: {
      details: "#E1F5F8",
      summary: {
        hover: "#B2EBF2;",
        main: "#CCF2F8"
      }
    },
    // Note 2
    "follow-up-checklist": {
      details: "#ECF5FF",
      summary: {
        hover: "#CCEAFF;",
        main: "#DDEFFF"
      }
    },
    // Note 2
    "referral-checklist": {
      details: "#ECF5FF",
      summary: {
        hover: "#CCEAFF;",
        main: "#DDEFFF"
      }
    },
    // Note 3
    "national-guidelines": {
      details: "#FCEAE8",
      summary: {
        hover: "#FCD0D0",
        main: "#FFDDDC"
      }
    },
    // Note 3
    "realtime-guidelines": {
      details: "#FCEAE8",
      summary: {
        hover: "#FCD0D0",
        main: "#FFDDDC"
      }
    },
    // Note 4 (default)
    note: {
      details: "#FEF8E7",
      summary: {
        hover: "#FFEAA6;",
        main: "#FFF2CC"
      }
    },
    // Note 5
    "priorities-guide": {
      border: "#D1D8DB",
      details: "#ffffff00",
      summary: {
        hover: "#ffffff00;",
        main: "#ffffff"
      }
    },
    // Note 6
    guidelines: {
      details: "#E2ECF3",
      summary: {
        main: "#C5D9E7",
        hover: "#A8C6DC"
      }
    },
    // Note 7
    highlight: {
      details: "#ECF5FF",
      summary: {
        hover: "#CCEAFF",
        main: "#DDEFFF"
      }
    },
    // Note 8
    urgent: {
      details: "#FCEAE8",
      summary: {
        hover: "#FCD0D0",
        main: "#FFDDDC"
      }
    },
    // Note 9
    educational: {
      details: "#E8F3F0",
      summary: {
        hover: "#AAE1D7",
        main: "#CCEBE6"
      }
    },
    collapsible: {
      border: "#D1D8DB",
      details: "#ffffff00",
      summary: {
        main: "#ffffff00",
        hover: "#D1D8DB"
      }
    }
  }
};
const palette = {
  ...defaultPalette,
  ...CDSPalette
};
const theme = createTheme({
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      // right aside also breaks here
      lg: 1280,
      xl: 1920
    }
  },
  constants: {
    height: {
      footerMin: 128,
      header: 64,
      headerXSExtra: 56,
      breadcrumbs: 40,
      asideHeader: 46
    },
    width: {
      contentMax: 640,
      //NOTE: Roughly 80ch, we cannot use ch, becuase we swap fonts and they have different sizes
      leftAside: 320,
      rightAside: 320,
      galleryMin: 940
    }
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": {
            outline: "none",
            boxShadow: `0 0 0 3px ${palette.secondary?.light}`
          }
        }
      },
      defaultProps: {
        disableRipple: true
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none"
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          "&.Mui-expanded": {
            margin: 0
          },
          margin: 0
        },
        root: {
          "&.Mui-expanded": {
            minHeight: 0
          },
          "@media print": {
            background: "transparent !important"
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&.Mui-focusVisible": {
            outline: "none",
            boxShadow: `0 0 0 3px ${palette.primary?.dark}`
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 10
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": {
            outline: "none",
            boxShadow: `0 0 0 3px ${palette.secondary?.light}`
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": {
            outline: "none",
            boxShadow: `0 0 0 3px ${palette.secondary?.light}`
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          "&::-webkit-scrollbar": {
            width: "4px"
          },
          "&::-webkit-scrollbar-track": {
            marginTop: "2px",
            backgroundColor: palette.background?.default
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: grey[500]
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          marginTop: "16px",
          marginBottom: "16px"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    }
  },
  shape: {
    borderRadius: 0
  },
  typography: {
    fontSize: 16,
    fontFamily: "Figtree, sans-serif",
    h1: {
      fontFamily: "Figtree, sans-serif",
      fontSize: "2.3565rem",
      fontWeight: 700
    },
    h2: {
      fontFamily: "Figtree, sans-serif",
      fontSize: "1.6667rem",
      fontWeight: 700
    },
    h3: {
      fontFamily: "Figtree, sans-serif",
      fontSize: "1.2853rem",
      fontWeight: 700
    },
    h4: {
      fontSize: "1.2853rem",
      fontWeight: 400
    },
    h5: {
      fontFamily: "Figtree, sans-serif",
      fontSize: "1rem",
      fontWeight: 700
    },
    h6: {
      fontFamily: "Figtree, sans-serif",
      fontSize: "1rem",
      fontWeight: 700
    },
    body1: {
      fontSize: "1rem"
    },
    body2: {
      fontSize: "0.9rem"
    },
    subtitle1: {
      fontFamily: "Figtree, sans-serif",
      fontWeight: 700
    },
    subtitle2: {
      fontFamily: "Figtree, sans-serif",
      fontWeight: 700
    },
    caption: {
      opacity: 0.85
    }
  }
});
export default theme;