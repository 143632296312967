import { NextWebVitalsMetric } from "next/app"

export function reportWebVitals({ name, label, value }: NextWebVitalsMetric) {
    window.dataLayer.push({
        event: "web-vitals",
        event_action: name,
        event_category:
            label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
        event_label: label,
        event_value: Math.round(name === "CLS" ? value * 1000 : value)
    })
}
