/* eslint-disable i18next/no-literal-string */
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
export const custom = {
  "h-dir"() {
    return <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>Icon_HDIR</title>
                <path d="M23,12.76A2.64,2.64,0,0,1,23,13a6.94,6.94,0,0,1-.67,2.57,7,7,0,0,1-1.47,2A7.06,7.06,0,0,1,9,13.61a6.82,6.82,0,0,1,0-1.86c0-.27.08-.53.12-.81l.07,0c.4.21.8.42,1.19.64a8.25,8.25,0,0,1,1.35.92,4.16,4.16,0,0,1,.94,1.19c.35.66.64,1.35.93,2,.14.33.27.67.42,1a1.28,1.28,0,0,0,.14.23.32.32,0,0,0,.36.12l.34-.14a.32.32,0,0,0,.17-.34,3.07,3.07,0,0,0-.14-.52c-.18-.55-.37-1.1-.54-1.65a4.13,4.13,0,0,1-.19-1.15,2.69,2.69,0,0,1,.15-1.13,1.39,1.39,0,0,1,.23-.41.49.49,0,0,1,.57-.19l1.12.34,2,.61a.93.93,0,0,0,.37.06.23.23,0,0,0,.21-.12A1.1,1.1,0,0,0,18.9,12a.28.28,0,0,0-.14-.27,1.4,1.4,0,0,0-.3-.15l-2.41-1a4.67,4.67,0,0,1-.83-.43,1.82,1.82,0,0,1-.82-1.25c-.05-.3-.08-.59-.11-.89s0-.64-.07-1,0-.7-.05-1a1.7,1.7,0,0,0,0-.22s0,0,.05,0c.26,0,.53-.12.8-.16a5.49,5.49,0,0,1,1-.06,6.87,6.87,0,0,1,3.81,1.14,7,7,0,0,1,2.81,3.58,6.73,6.73,0,0,1,.35,1.6c0,.16,0,.32,0,.49v.43ZM16.63,7.54a.68.68,0,0,0-.5.2l-.47.46a.69.69,0,0,0-.2.59.66.66,0,0,0,.46.55.63.63,0,0,0,.69-.15l.28-.28a3.2,3.2,0,0,0,.25-.27.62.62,0,0,0,.07-.7A.66.66,0,0,0,16.63,7.54Z" />
                <path d="M6.63,5l.26.08A.7.7,0,0,1,7.21,6c-.1.22-.2.43-.31.65a.71.71,0,0,1-1.35-.35A.78.78,0,0,1,5.64,6l.27-.55A.68.68,0,0,1,6.44,5h.19Z" />
                <path d="M9.47,9.78l-.38,1.15h0L7.5,10.14a.7.7,0,0,0-.4-.08.45.45,0,0,0-.38.41,3.23,3.23,0,0,0,0,.44c0,.76.06,1.52.1,2.29s0,1.43.08,2.15c0,.32,0,.64,0,1a.83.83,0,0,1,0,.22.3.3,0,0,1-.23.24,2.87,2.87,0,0,1-.41.05.29.29,0,0,1-.3-.2,2.77,2.77,0,0,1-.12-.41l-1-4.38L4.17,9.22a1.8,1.8,0,0,0-.34-.66,4,4,0,0,0-.93-.85l-1.56-1a2.11,2.11,0,0,1-.23-.19A.31.31,0,0,1,1,6.12l.21-.35a.28.28,0,0,1,.31-.11A1.63,1.63,0,0,1,2,5.84L6,7.93,8.5,9.24l1,.53Z" />
                <path d="M13.14,6.1v.44c0,.15,0,.3,0,.45v.69c0,.5,0,1,0,1.49a3,3,0,0,1-.35,1.37,1.71,1.71,0,0,1-.21.26.58.58,0,0,1-.66.12l-1-.46L9.48,9.79A7.16,7.16,0,0,1,13.14,6.1Z" />
            </svg>;
  },
  null() {
    return <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" />;
  },
  regional() {
    return <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="2.5" />
                <circle cx="4.5" cy="12" r="2.5" />
                <circle cx="19.5" cy="12" r="2.5" />
                <circle cx="12" cy="19.5" r="2.5" />
                <circle cx="12" cy="4.5" r="2.5" />
            </svg>;
  },
  rki() {
    return <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M5.73,8.69a12.26,12.26,0,0,1,1.83-.13,3.1,3.1,0,0,1,2,.48,1.54,1.54,0,0,1,.56,1.27A1.61,1.61,0,0,1,9,11.8v0A1.55,1.55,0,0,1,9.82,13a14.72,14.72,0,0,0,.49,1.71H8.91a7.16,7.16,0,0,1-.43-1.41c-.16-.76-.42-1-1-1H7.09v2.38H5.73ZM7.09,11.3h.54c.68,0,1.09-.34,1.09-.88s-.38-.83-1-.84a4.8,4.8,0,0,0-.62,0Z" />
                <path d="M11.33,8.61h1.36v2.68h0c.14-.24.28-.45.41-.67l1.38-2H16.2l-2,2.58,2.11,3.48H14.7l-1.48-2.62-.53.64v2H11.33Z" />
                <path d="M18.51,8.61v6.06H17.13V8.61Z" />
                <path d="M12.13,3H19.8a1.93,1.93,0,0,1,2.05,2c0,1.21,0,12.26,0,13.52a1.92,1.92,0,0,1-2,2l-14.53,0a11.81,11.81,0,0,1-1.19,0,1.94,1.94,0,0,1-1.79-2c0-1.83,0-3.66,0-5.49q0-4,0-8a1.94,1.94,0,0,1,2-2ZM19.9,4.92l-15.58,0q0,6.83,0,13.63l15.57,0" />
            </svg>;
  }
} as const;
export interface CustomIconProps extends SvgIconProps {
  name: keyof typeof custom;
}
const customIcons = {
  ...custom
};
export default function CustomIcon({
  name,
  ...props
}: CustomIconProps) {
  const CustomIconComponent = customIcons[name];
  return <SvgIcon {...props} data-sentry-element="SvgIcon" data-sentry-component="CustomIcon" data-sentry-source-file="custom.tsx">
            <CustomIconComponent data-sentry-element="CustomIconComponent" data-sentry-source-file="custom.tsx" />
        </SvgIcon>;
}