import CustomIcon, { CustomIconProps, custom } from "components/icons/custom";
import MaterialSymbolIcon, { material } from "components/icons/materialsymbols";
import { SvgIconProps } from "@mui/material/SvgIcon";
export const icons = {
  custom,
  material
};
const customIcons = {
  ...custom
};
const materialSymbolIcons = {
  ...material
};
export type IconType = keyof typeof material | CustomIconProps["name"] | "";
export interface IconProps extends SvgIconProps {
  name: IconType | string;
}
export function Icon({
  name,
  ...props
}: IconProps) {
  if (name in customIcons) {
    return <CustomIcon name={name as keyof typeof customIcons} {...props} />;
  }
  if (name in materialSymbolIcons) {
    return <MaterialSymbolIcon name={name as keyof typeof materialSymbolIcons} {...props} />;
  }
  return null;
}